<template>
  <main class="login">
    <div class="container">
      <h1><img src="/img/logo-black-gold.png" alt="Logo" /></h1>
      <form @submit.prevent="changePassword">
        <div class="password">
          <label for="password">{{ $t("overall.password") }}</label>
          <input id="password" placeholder="•••••••••" type="password" name="password" v-model="password" />
        </div>
        <div class="password">
          <label for="repeat_password">{{ $t("Repeat password") }}</label>
          <input
            id="repeat_password"
            placeholder="•••••••••"
            type="password"
            name="repeat_password"
            v-model="repeat_password"
          />
        </div>
        <div class="error" v-if="error">{{ error }}</div>
        <button class="cta">{{ $t("Change Password") }}</button>
      </form>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "Login",
  data() {
    return {
      email: "",
      repeat_password: "",
      error: null,
      loading: false,
    };
  },
  methods: {
    changePassword() {
      this.$axios
        .post("login/reset-password", {
          password: this.password,
          repeat_password: this.repeat_password,
        })
        .then((res) => {
          if (res.data) {
            this.$cookies.set("jkt", res.data.jkt);
            this.$cookies.set("usr_lang", "en");
            this.$store.commit("TOGGLE_MENU", true);
            this.$axios.defaults.headers.common["Authorization"] = res.data.jkt;

            //important !
            this.$store.dispatch("setupLanguages");
            this.$store.dispatch("setupProfile", res.data.user);

            this.$router.push({ name: "properties" });
          } else {
            this.error = "Nom d'utilisateur ou mot de passe incorrect";
          }
        })
        .catch((res) => {
          this.error = "Nom d'utilisateur ou mot de passe incorrect";
        });
    },
  },
});
</script>

<style scoped></style>
